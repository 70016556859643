@import "../../variables";
.plans-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 100px 0;
  .plans-container {
    max-width: $appmaxwidth;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url(../../images/background.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    color: #fff;
    > h1 {
      color: #cad0ec;
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 4px;
    text-align: center;
    margin-bottom: 0;
    }
    span{
      color: #f03535;
    }
    > h4 {
      color: rgba(202, 209, 237, 1.00);
    font-family: ".SFNSDisplay", "SFProDisplay-Regular", "SFUIDisplay-Regular", ".SFUIDisplay", "-apple-system", "BlinkMacSystemFont", sans-serif;
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 1.2;
    text-align: center;

    }
   .tabs-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin: 40px 0;
    button{
      background-color: transparent;
      border: none;
      outline: o;
      color: #cad1ed;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2;
      
      padding: 0.8rem 1.4rem;
    }
    .selected{
      background-color: #262334;
      border-radius: 0.25rem;
    }
   }
    > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
