@import "../../variables";
.navbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 500;
  height: 80px;
  color: #fff;
  > .navbar-container {
    width: 100%;
    max-width: $appmaxwidth;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 100px;
    > a {
      text-decoration: none;
      >div{
        display: flex;
        justify-content: center;
        align-items: center;
        >h6{
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 5px;
          color: #f03535;
        }
      }
       img{
        width: 80px;
        height: 80px;
        position: absolute;
        left: calc(50% - 40px);
      }
    }
    > div {
      display: flex;
      > a {
        color: rgba(255,255,255,.85);
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 1px;
        padding: 0 25px;
        text-decoration: none;
        margin-top: 11px;
      }
      > a:hover {
        transform: scale(1.2);
      }
      > div {
        background-color: #f03535;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 24px;
        margin-left: 25px;
        border: 1px solid #f03535;
        border-radius: 5px;
        > a {
          font-size: 14px;
          color: #fff;
          text-decoration: none;
          font-weight: 400;
          letter-spacing: 2px;
        }
      }
    }
  }
}
.navbar-wrapper.active {
  position: fixed;
  .navbar-container {
    > a {
      > div {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1160px) {
  .navbar-wrapper {
    display: none;
  }
}
