@import "../../variables";
.features-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 50px 0;
  background-color: #1a1826;
  > .features-container {
    max-width: $appmaxwidth;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    > img{
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 500px;
      max-width: 100%;
      z-index: 1;
    }
    h1{
      color: #CAD0F0;
      font-size: 30px;
      letter-spacing: 2px;
      font-weight: 500;
      padding: 320px 0 30px;
      text-align: center;
    }
    > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .featuresCard-wrapper1{
        background: linear-gradient(218deg,rgb(32, 29, 48) 0%,rgb(24,22,35) 100%);
        border-radius: 10px;
        padding: 15px;
        max-width: 350px;
        margin: 10px;
        img{
          width: 44px;
        }
        h5{
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 2px;
          margin: 0;
          padding: 12px 0 6px;
        }
        p{
          color: #cad0ec;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 1px;
          text-align: justify;
        }
      }
      .featuresCard-wrapper2{
        background: linear-gradient(218deg,rgb(32,29,47) 0%,rgb(24,22,35) 100%);
        border-radius: 10px;
        padding: 15px;
        max-width: 350px;
        margin: 10px;
        img{
          width: 44px;
        }
        h5{
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 2px;
          margin: 0;
          padding: 12px 0 6px;
        }
        p{
          color: #cad0ec;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 1px;
          text-align: justify;
        }
      }
      .featuresCard-wrapper3{
        background: linear-gradient(218deg,rgb(32,29,47) 0%,rgb(24,22,35) 100%);
        border-radius: 10px;
        padding: 15px;
        max-width: 350px;
        margin: 10px;
        img{
          width: 44px;
        }
        h5{
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 2px;
          margin: 0;
          padding: 12px 0 6px;
        }
        p{
          color: #cad0ec;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 1px;
          text-align: justify;
        }
      }
    }
  }
}
