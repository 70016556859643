@import "../../variables";
.mobileNavbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 20px;
  z-index: 500;
  > .mobileNavbar-container {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    > .top {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 40px);
      padding: 0 20px;
      > a {
        text-decoration: none;
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          > h6 {
             font-size: 18px;
              font-weight: 400;
              letter-spacing: 5px;
              color: #f03535;
              margin: 0;
              margin-top: 10px;
          }
          > img {
            width: 60px;
            height: 60px;
            margin-top: -10px;
            margin-left: -10px;
            padding: 0;
            
          }
        }
      }
      > img {
        width: 40px;
        margin-top: -20px;
      }
      > a {
        height: 60px;
        > img {
          width: 150px;
        }
      }
    }

    > .bottom {
      display: flex;
      flex-direction: column;
      text-align: left;
      height: 80%;
      width: 100%;
      
    background-color: #1a1826;
      > a {
        color: rgba(255,255,255,.85);
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1px;
        text-decoration: none;
        padding: 10px 20px;
      }
      > div {
        background-color: #f03535;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 24px;
        margin-left: 20px;
        border: 1px solid #f03535;
        border-radius: 5px;
        margin-bottom: 20px;
        width: max-content;
        margin-top: 10px;
        > a {
          font-size: 14px;
          color: #fff;
          text-decoration: none;
          font-weight: 400;
          letter-spacing: 2px;
        }
      }
    }
  }
}
.mobileNavbar-wrapper.active {
  position: fixed;
  .mobileNavbar-container {
    > .top {
      > a {
        > div {
          display: none;
        }
      }
    }
  }
}
@media screen and (min-width: 1161px) {
  .mobileNavbar-wrapper {
    display: none;
  }
}
