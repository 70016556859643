.planCard-wrapper {
  width: 300px;
  margin: 20px;
  background-color: #1f1e2f;
    border-radius: 10px;
    padding: 20px;
  .top {
 
   
    display: flex;
    flex-direction: column;
    
    > h3 {
      color: #cad1ed;
      font-size: 24px;
      font-weight: 400;
      line-height: 1.2;
      margin: 0;
    }
    > h1 {
      margin-top: 10px;
      color: #fff;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
    }
  }
  > .bottom {
   
    display: flex;
    flex-direction: column;
    
    > ul {
      padding: 0;
      margin: 0;
      margin-left: 10px;
      color: #fff;
      margin-bottom: 10px;
      // height: 200px;
      > li {
        color: #cad1ed;
    font-size: 10px;
    line-height: 2.8;
      }
    }
    > select {
      font-size: 14px;
      padding: 10px;
      background-color: transparent;
      width: 100%;
      margin: 0;
      outline: 0;
      color: #fff;
      border: none;
      font-weight: bold;
      border: 1px solid #57556f!important;
      border-radius: 5px;
      margin: 10px 0;
    }
    p{
      color: #9997ba;
    font-size: 8px;
    }
    > a {
      text-decoration: none;
      > button {
        cursor: pointer;
        border: none;
        background-color: #f03535;
        padding: 10px;
        display: block;
        text-align: center;
        border-radius: 5px;
        font-size: 12px;
        width: 100%;
        font-weight: bold;
        margin-top: 10px;
        color: #fff;
      }
    }
  }
}
