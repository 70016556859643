@import "../../variables";
.home-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  > .home-container {
    max-width: $appmaxwidth;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    height: 725px;
    min-height: 576px;
    background-image: url(../../images/background.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;
    padding-top: 60px;
    overflow: hidden;
    > h1 {
      color: #fff;
      font-size: 22px;
      font-weight: 300;
      letter-spacing: 9px;
      margin-bottom: 10px;
      margin-top: 0;
    }
    > h2 {
      color: #cad0ec;
      font-size: 36px;
      font-weight: 500;
      letter-spacing: 4px;
      margin-bottom: 10px;
      margin-top: 0;
    }
    div{
      display: flex;
    }
    a {
      text-decoration: none;
      .b1 {
        border: 1px solid #f03535;
        border-radius: 5px;
        color: #f03535;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        padding: 10px 24px!important;
        text-align: center;
        background-color: transparent;
        outline: 0;
        margin-right: 5px;
        cursor: pointer;
      }
      .b2{
        border: 1px solid #f03535;
        border-radius: 5px;
        color: #fff!important;
        background-color: #f03535;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        padding: 10px 24px!important;
        outline: 0;
        text-align: center;
        margin-left: 5px;
        cursor: pointer;
    
      }
    }
  }
}
