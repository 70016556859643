@import "../../variables";
.footer-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 3rem 0;
  z-index: 100;
  .footer-content {
    max-width: $appmaxwidth;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding: 20px;
    padding-bottom: 0;
    padding-top: 50px;
    p{
      color: #fff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 0;

    }
   >div{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
     >div{
      display: flex;
      flex-direction: column;
      margin: 20px 80px;
      >h1{
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 2px;
      }
      a{
        text-decoration: none;
        color: #fff;
        margin-bottom: 10px;
      }
      >div{
        display: flex;
        img{
          width: 30px;
          margin-right: 10px;
        }
      }
    }}
  }
}
@media screen and (max-width: 768px) {
  .footer-wrapper {
    .footer-content {
     >div{
       display: flex;
      flex-direction: column;
      align-items: flex-start;
     }
    }
  }
}
