@import "../../variables";
.platforms-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 50px 0;
  background-color: #1a1826;
  position: relative;
  // .overlay{
  //   position: absolute;
  //   top: 60%;
  //   bottom: 0;
  //   right: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: #16141f;
  //   transform: skewY(18deg);
  //   transform-origin: top left;
  // }
  > .platforms-container {
    max-width: $appmaxwidth;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
        h1{
      color: #CAD0F0;
      font-size: 30px;
      letter-spacing: 2px;
      font-weight: 500;
      padding: 50px 0 30px;
      text-align: center;
    }
    >.content{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        >img{
            width: 100px;
            margin: 60px;
        }
    }
    .join{
          z-index: 100;
          background: linear-gradient(238deg,#f56e6e 0%,#f03535 100%);
          border-radius: 10px;
          padding: 30px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
          
          position: relative;
          top: 100px;
          h2{
            color: #fff;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 5px;
            margin: 0;
          }
          h3{
            color: #fff;
            font-size: 30px;
            font-weight: 400;
            letter-spacing: 5px;
            margin: 15px 0;
            text-transform: uppercase;
          }
          img{
            position: absolute;
            top: 20px;
            right: 60px;
            height: 250px;
            width: 100%;
            max-width: 200px;
          }
          a{
            button{
              background-color: #fff;
              border-radius: 5px;
              color: #16141f;
              display: inline-block;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 1px;
              padding: 10px 36px;
              border: none;
              outline: 0;
            }
          }
    }
  }
}
@media screen and (max-width: 650px) {
  .platforms-wrapper {
    > .platforms-container {
      .join{
        display: flex;
        flex-direction: column;
        >img{
          position: relative;
          top: 0;
          right: 0;
        }
      }
    }
  }
}