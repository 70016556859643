@import "../../variables";
.selectplan-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 50px 0;
  background-color: #19141F;
  > .selectplan-container {
    max-width: $appmaxwidth;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
        h1{
      color: #CAD0F0;
      font-size: 30px;
      letter-spacing: 2px;
      font-weight: 500;
      padding: 50px 0 30px;
      text-align: center;
    }
    >div{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        img{
            width: 350px;
            margin: 10px;
        }
    }
  }
}